<template>
  <div class="container flex-center">
     {{ data }}
    <div class="info-box flex-column-center">
      <div class="title">请选择拍摄方式{{ $route.params.id }}</div>
      <div class="main flex-between">
        <div class="common flex-center mobile" :style="{ background: backgroundColor }" @click="handleTakephoto">
          <div class="flex-column-center" @click="handleTakephoto">
            <div class="main-top">
              <img src="@/assets/images/take-photos.png" alt="" />
            </div>
            <div class="main-center">
              手机
            </div>
            <div class="main-bottom" >
              请使用手机自带的  后置摄像头请他人  对您进行拍摄
            </div>
          </div>
        </div>
        <div class="common flex-center" :style="{ background: backgroundColor }" @click="handleMachine">
          <div class="flex-column-center" @click="handleMachine">
            <div class="main-top">
              <img src="@/assets/images/machine-libre-service.png" alt="" />
            </div>
            <div class="main-center">
              自助机
            </div>
            <div class="main-bottom right-box">
              请在自助机上选择 新版社保卡频道 进入，按照提示 完成拍摄
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="van-overlay" style="z-index: 2005;" v-show="isVisible"></div>
    <div v-if="isVisible" role="dialog" class="van-dialog" aria-labelledby="错误">
        <div class="header">{{errorMessage}}</div>
        <div class="footer" @click="reloadWeb">
            <button class="van-button van-button--default van-button--large van-dialog__confirm">
                <span class="confirm-span">确认</span>
            </button>
        </div>
    </div>
    <footer>
    </footer>
  </div>
</template>
<script>
import { getWork, getUserDesc} from "@/api/app-base";
export default {
  data() {
    return {
      data: null,
      backgroundColor: '#ffffff',
      isVisible: false,
      errorMessage: null,
      isAble: false,
    };
  },
  created() {
    if(this.checkParams()){
      this.saveParam();
      this.getStyle();
      this.getWorkT();
    }
  },
  methods: {
    handleTakephoto(){
      if(this.isAble ===true){
        this.$router.push('/mobileshooting');
      }
    },
    handleMachine(){
      if(this.isAble ===true){
        this.$router.push('/shootingbranches')
      }
    },
    getStyle(){
        // 接口获取样式
        const busId =  this.$store.state.sid
        getUserDesc(busId).then(
          (res) => {
            if(res.code == 0){
              const userConfig = res.data.userConfig;
              this.$store.commit('setPhotoSpecCode',userConfig.photoSpecCode);
              this.$localStorage.set('styleColor', userConfig.backgroundColor);
              this.$localStorage.set('styleColorLight', userConfig.backgroundColorLight);
              
              this.backgroundColor=userConfig.backgroundColor
              if(userConfig.hasOwnProperty("descName")){
                if(userConfig.descName){
                  this.$store.commit('setDescName', userConfig.descName);
                }
              }
              if(userConfig.hasOwnProperty("descUrl")){
                if(userConfig.descUrl){
                  this.$store.commit('setDescUrl', userConfig.descUrl);
                }
              }
              if(userConfig.hasOwnProperty("descContent")){
                if(userConfig.descContent){
                  this.$store.commit('setDescContent', userConfig.descContent);
                }
              }

              const userDescs = []
              res.data.photoUserDesc.forEach(function(value, index) {
                userDescs.push(value.url);
              });
              this.$store.commit('setDescImg', userDescs.join(','))

              const machineUserDescs = []
              res.data.machineUserDesc.forEach(function(value, index) {
                machineUserDescs.push(value.url);
              });
              this.$store.commit('setDescImgMachine', machineUserDescs.join(','))

              this.isVisible = false
              this.isAble = true;
            }else{
              this.isAble = false;
              this.errorView()
            }
          },
          (error) => {
            console.log(error)
            this.isAble = false;
            this.backgroundColor="#d3d3d3"
            this.messageBox(error.response.data.msg)
          }
        )
    },
    getWorkT(){
      getWork().then(res=>{
      },
      (error) => {
        console.error(error);
      })
    },
    checkParams(){
      if(this.$route.query.sid == undefined || this.$route.query.sid == ""){
        this.messageBox("缺少业务流水号")
        return false
      }
      return true
    },
    saveParam(){
      this.$store.commit('setSid', this.$route.query.sid)
      navigator.geolocation.getCurrentPosition(
          (position) => {
            const temp = this.wgs84ToGcj02(position.coords.latitude,position.coords.longitude)
            this.$store.commit('setLng',temp[1]);
            this.$store.commit('setLat',temp[0]);
          },
          (error) => {
            this.$store.commit('setLng',0);
            this.$store.commit('setLat',0);
          },
          {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          }
        );
    },
    reloadWeb(){
      window.location.reload();
    },
    errorView(){
      this.backgroundColor="#d3d3d3"
      this.messageBox("服务错误")
    },
    messageBox(msg){
      this.isVisible = true
      this.errorMessage = msg
    },
    transformLat(x, y) {
      let ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
      ret += (20.0 * Math.sin(6.0 * x * Math.PI) + 20.0 * Math.sin(2.0 * x * Math.PI)) * 2.0 / 3.0;
      ret += (20.0 * Math.sin(y * Math.PI) + 40.0 * Math.sin(y / 3.0 * Math.PI)) * 2.0 / 3.0;
      ret += (160.0 * Math.sin(y / 12.0 * Math.PI) + 320 * Math.sin(y * Math.PI / 30.0)) * 2.0 / 3.0;
      return ret;
    },
    transformLon(x, y) {
      let ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
      ret += (20.0 * Math.sin(6.0 * x * Math.PI) + 20.0 * Math.sin(2.0 * x * Math.PI)) * 2.0 / 3.0;
      ret += (20.0 * Math.sin(x * Math.PI) + 40.0 * Math.sin(x / 3.0 * Math.PI)) * 2.0 / 3.0;
      ret += (150.0 * Math.sin(x / 12.0 * Math.PI) + 300.0 * Math.sin(x / 30.0 * Math.PI)) * 2.0 / 3.0;
      return ret;
    },
    wgs84ToGcj02(lat, lon) {
      const a = 6378245.0;
      const ee = 0.00669342162296594323;
      let dLat = this.transformLat(lon - 105.0, lat - 35.0);
      let dLon = this.transformLon(lon - 105.0, lat - 35.0);
      const radLat = lat / 180.0 * Math.PI;
      let magic = Math.sin(radLat);
      magic = 1 - ee * magic * magic;
      const sqrtMagic = Math.sqrt(magic);
      dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * Math.PI);
      dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * Math.PI);
      const mgLat = lat + dLat;
      const mgLon = lon + dLon;
      return [mgLat, mgLon];
    }
  },
  components: {
  }
};
</script>
<style lang="less" scoped>
.container {
  .info-box {
     flex: 1;
  }
  .title {
    font-size: 42px;
    color: #000000;
    margin-bottom: 200px;
    font-family: Adobe Heiti Std;
    font-weight: bold;
  }
  .main {
    .common {
      width: 240px;
      height: 560px;
      background: #326EDE;
      border-radius: 24px;
      padding: 0 34px 160px 34px;
      margin: 0 20px;
      position: relative;
    }
    .mobile {
      margin-right: 16px;
    }
    .main-top {
      width: 172px;
      height: 238px;
      position: absolute;
      top: -5px;
      left: 50%;
      transform: translate(-50%, -50%);
      mg {
        width: 100%;
        height: 100%;
      }
    }
    .main-center {
      font-size: 46px;
      font-weight: bold;
      font-family: YEFONTAoYeHei;
      color: #FFFFFF;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .main-bottom {
      width: 73%;
      font-size: 25px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .right-box {
      top: 58% !important;
    }
  }
}
.van-dialog {
  position: fixed;
  top: 45%;
  left: 50%;
  width: 15rem;
  height: 14vh;
  overflow: hidden;
  font-size: 0.8rem;
  transf: translate3d(-50%,-50%,0);
  backface-visibility: hidden;
  transition: .3s;
  z-index: 9999;
  .header {
    height: 6vh;
    font-weight: 500;
    line-height: 8vh;
    text-align: center;
    vertical-align: bottom;
  }
  .footer {
    height: 6vh;
    padding-top: 0.5rem;
  }
  .van-button {
    border-top: 0.2px solid #d0d0d0;
  }
  .confirm-span {
    color: #1989fa;
  }
}
footer {
  height: 70px;
  background-color: #ccc;
}
</style>
