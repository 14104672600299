import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    sid: "",
    upload: '',                 // 照片（base64）
    uploadFileName: 'sss',      // 照片名称
    userUuid: '235',            // 用户ID
    photoSpecCode: '123',       // 照片规格CODE
    lng: "121.370752",          // 经度
    lat: "37.520022",           // 维度
    styleColor: "#000000",      // 样式背景1
    styleColorLight: "#000000", // 样式背景2
    descImg: "",                // 轮播图(说明 手机)
    descImgMachine: "",         // 轮播图(说明 自助机)
    descName: "",               // 要求页面URL
    descUrl: "",                // 要求页面URL
    descContent: ""             // 要求页面内容
  },
  mutations: {
    setSid(state, value) {
      state.sid = value;
    },
    setUpload(state, value) {
      state.upload = value;
    },
    setUploadFileName(state, value) {
      state.uploadFileName = value;
    },
    setLng(state, value) {
      state.lng = value;
    },
    setLat(state, value) {
      state.lat = value;
    },
    setUserUuid(state, value) {
      state.userUuid = value;
    },
    setPhotoSpecCode(state, value) {
      state.photoSpecCode = value;
    },
    setStyleColor(state, value) {
      state.styleColor = value;
    },
    setStyleColorLight(state, value) {
      state.styleColorLight = value;
    },
    setDescImg(state, value) {
      state.descImg = value;
    },
    setDescImgMachine(state, value) {
      state.descImgMachine = value;
    },
    setDescName(state, value) {
      state.descName = value;
    },
    setDescUrl(state, value) {
      state.descUrl = value;
    },
    setDescContent(state, value) {
      state.descContent = value;
    }
  },
  actions: {},
  getters: {}
})

export default store